<template>
	<div class="">
		<div v-if="pageWidth >= 1005">
			<div class="bgcolor" style="position: fixed;width: 100%;left: 0;top: 0;z-index: -1;">
				<div class="flex-row-center-center"
					style="padding: 10px;width: 100%;height: 100vh;box-sizing: border-box;">
					<video src="https://www.lczrxb.com/prod-api/profile/upload/lczVideo.mp4" autoplay="" controls="" loop=""
						muted="" poster=""
						style="object-fit: cover;object-position: bottom center;border: 1px solid #5f5f5f;width: 100%;height: 100%;"></video>
				</div>
			</div>
			<div class="flex-column-center-center bgcolor"
				style="width: 100%;position: relative;z-index: 10;display: -webkit-flex;background-size: 100%;margin-top: 100vh;overflow-x: hidden;">
				<div class="flex-column-center-center"
					style="width: 100%;;box-sizing: border-box;z-index: 999;padding: 10px;">
					<div ref="card1" class="flex-row-start-center bgimg"
						style="width: 100%;padding: 100px 100px;box-sizing: border-box;border: 1px solid #5f5f5f;">
						<div class="testleft flex-column-center-center" style="width: 50%;position: relative;">
							<img src="../assets/gf1.png" style="width: 100%;height: auto;" />
							<div ref="animateCard1">
								<img  v-if="shouldCard1Animate" class="bounceleft" src="../assets/ipone.png"
									style="position: absolute;bottom: 0px;right: -80px;width:220px;height: auto;" />
							</div>
						</div>
						<div class="testright flex-column-center-start"
							style="white-space: pre-wrap;padding: 0 80px;flex-shrink: 0;flex: 1;">
							<img src="../assets/guanyuLCZ.png" style="width: 200px;height: auto;" />
							<span class="whiteColor t5BoldFont"
								style="padding: 50px 0;line-height: 1.8;text-indent: 2em;flex: 1;">{{card1Note}}</span>
							<a href="/store" class="t5BoldFont whiteColor flex-row-start-center" style="width: 100px;">
								<img src="../assets/getmore.png" style="width: 100px;height: auto;" />
							</a>
							<div class="flex-row-end-center" style="width: 100%;margin-top: 50px">
								<img src="../assets/guanyuQH.png" style="width: 500px;height: auto;" />
							</div>
						</div>

					</div>

					<div ref="card2" class="bgimg flex-column-start-center"
						style="width: 100%;box-sizing: border-box;border: 1px solid #5f5f5f;margin-top: 10px;height: 1200px;">
						<div class="flex-coloumn-center-center"
							style="width: 100%;position: relative;flex: 1;box-sizing: border-box;">
							<div v-if="pageWidth >= 2000" class="flex-row-center-center"
								style="width: 100%;height: 100%;">
								<img src="../assets/home_guanyu4.png"/>
								<div class="flex-row-center-center" @mousemove="handleMouseMove" @mouseleave="resetRotation" style="margin-left: -150px;"><img src="../assets/home_guanyu2.png" :style="imgStyle"/></div>
								<img src="../assets/home_guanyu3.png"
									style="margin-left: -150px;" />
								<div ref="animateCard2" class="flex-row-end-end"
									style="position: absolute;left: 60%;bottom: 0;z-index: 4;">
									<img v-if="shouldCard2Animate" class="bounce"
										src="../assets/store_pic_dynamic_1.png" style="width:180px;height: auto;" />
								</div>
							</div>
							<div v-else class="flex-row-center-center"
								style="width: 100%;height: 100%;">
								<img src="../assets/home_guanyu4.png" style="width: 300px;height: auto;" />
								<div class="flex-row-center-center" @mousemove="handleMouseMove" @mouseleave="resetRotation" style="margin-left: -200px;"><img src="../assets/home_guanyu2.png" :style="imgStyle"/></div>
								<img src="../assets/home_guanyu3.png"
									style="width: 300px;height: auto;margin-left: -200px;" />
								<div ref="animateCard2" class="flex-row-end-end"
									style="position: absolute;left: 60%;bottom: 0;z-index: 4;">
									<img v-if="shouldCard2Animate" class="bounce"
										src="../assets/store_pic_dynamic_1.png" style="width:180px;height: auto;" />
								</div>
							</div>
						</div>
						<div class="testdown flex-row-start-center"
							style="width: 100%;padding: 50px;box-sizing: border-box;">
							<img src="../assets/home_guanyu5.png" style="width: 200px;height: auto;" />
							<a href="/store" class="t5BoldFont whiteColor flex-row-start-center" style="width: 100px;">
								<img src="../assets/getmore.png"
									style="width: 100px;height: auto;margin-left: 20px;z-index: 3;" />
							</a>
						</div>
					</div>

					<div ref="card3" class="flex-row-start-center bgimg"
						style="width: 100%;padding: 100px 100px;box-sizing: border-box;border: 1px solid #5f5f5f;margin-top: 10px;">
						<div class="testleft flex-column-center-center" style="width: 50%;position: relative;">
							<img src="../assets/gf2.png" style="width: 100%;height: auto;" />
							<div ref="animateCard3">
								<img v-if="shouldCard3Animate" class="movedownToup" src="../assets/ipone.png"
									style="position: absolute;bottom: 0px;right: -80px;width:220px;height: auto;" />
							</div>
							
						</div>
						<div class="testright flex-column-center-start"
							style="white-space: pre-wrap;padding: 0 80px;flex-shrink: 0;flex: 1;">
							<img src="../assets/home_miandan.png" style="width: 300px;height: auto;" />
							<div class="flex-column-center-start" style="padding: 50px 0;">
								<span class="whiteColor t5BoldFont"
									style="line-height: 1.8;text-indent: 2em;">{{card3Note1}}</span>
								<span class="whiteColor t5BoldFont"
									style="line-height: 1.8;text-indent: 2em;">{{card3Note2}}</span>
								<span class="whiteColor t5BoldFont"
									style="line-height: 1.8;text-indent: 2em;">{{card3Note3}}</span>
							</div>
							<a href="/dishes?position=card2" class="t5BoldFont whiteColor flex-row-start-center"
								style="width: 100px;">
								<img src="../assets/getmore.png" style="width: 100px;height: auto;" />
							</a>
						</div>
					</div>

					<!-- 水平匀速循环滚动的图片 -->
					<div ref="card4" class="flex-column-start-start bgimg"
						style="width: 100%;padding: 50px 50px;box-sizing: border-box;border: 1px solid #5f5f5f;margin-top: 10px;height: 800px;">
						<div class="flex-column-start-start" style="width: 100%;height: 100%;">
							<img src="../assets/home_meishi.png" style="width: 200px;height: auto;" />
							<div class="flex-row-center-center" style="flex: 1;width: 100%;">
								<!-- <dishes-banner></dishes-banner> -->
								<seamless-scroll style="width: 100%;height: 100%;"></seamless-scroll>
							</div>
						</div>
					</div>
					<bottom-view style="width: 100%;"></bottom-view>
				</div>
			</div>
			<div v-if="shouldCard4Animate" class="bgcolor"
				style="width: 100%;position: fixed;right: 0;bottom: 0;height: 100vh;">
			</div>
		</div>
		<div v-else>
			<div class="bgcolor" style="position: fixed;width: 100%;left: 0;top: 0;z-index: -1;">
				<div class="flex-row-center-center"
					style="padding: 10px;width: 100%;height: 55vh;box-sizing: border-box;">
					<video src="http://8.138.117.36:8080/profile/upload/lczVideo.mp4" autoplay="" controls="" loop=""
						muted="" poster=""
						style="object-fit: cover;object-position: bottom center;border: 1px solid #5f5f5f;width: 100%;height: 100%;"></video>
				</div>
			</div>
			<div class="flex-column-center-center bgcolor"
				style="width: 100%;position: relative;z-index: 10;display: -webkit-flex;background-size: 100%;margin-top: 55vh;overflow-x: hidden;">
				<div class="flex-column-center-center"
					style="width: 100%;;box-sizing: border-box;z-index: 999;padding: 10px;">
					<div ref="card1" class="flex-coloumn-center-center bgimg"
						style="width: 100%;padding: 20px 20px;box-sizing: border-box;border: 1px solid #5f5f5f;">
						<div class="testright flex-column-center-start" style="white-space: pre-wrap;width: 100%">
							<img src="../assets/guanyuLCZ.png" style="width: 150px;height: auto;" />
							<span class="whiteColor t5BoldFont"
								style="padding: 50px 0;line-height: 1.8;text-indent: 2em;">{{card1Note}}</span>
							<a href="/store" class="t5BoldFont whiteColor flex-row-start-center" style="width: 100px;">
								<img src="../assets/getmore.png" style="width: 100px;height: auto;" />
							</a>
							<div class="flex-row-start-center" style="width: 100%;margin-top: 30px">
								<img src="../assets/guanyuQH.png" style="width: 300px;height: auto;" />
							</div>
						</div>
						<div class="flex-column-center-center" style="width: 100%;position: relative;margin-top: 20px;">
							<img src="../assets/gf1.png" style="width: 100%;height: auto;" />
							<div ref="animateCard1">
								<img v-if="shouldCard1Animate" class="bounceleft" src="../assets/ipone.png"
									style="position: absolute;bottom: 0px;right: 0px;width:100px;height: auto;" />
							</div>
							
						</div>
					</div>
					<div ref="card2" class="bgimg flex-column-center-center"
						style="width: 100%;padding: 10px 10px;box-sizing: border-box;border: 1px solid #5f5f5f;margin-top: 10px;height: 500px;">
						<div class="flex-row-spacebetween-center" style="width: 100%;flex: 1;;position: relative;">
							<img src="../assets/home_guanyu4.png" style="width: 100px;height: auto;" />
							<img src="../assets/home_guanyu3.png"
								style="width: 100px;height: auto;" />
							<div class="flex-row-center-center"
								style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;">
								<div class="flex-row-center-center" @mousemove="handleMouseMove" @mouseleave="resetRotation" style=""><img class="guoClass" src="../assets/home_guanyu2.png" :style="imgStyle"/></div>
							</div>
							<div ref="animateCard2" class="flex-row-end-end"
								style="position: absolute;left: 60%;bottom: 0;z-index: 4;">
								<img v-if="shouldCard2Animate" class="bounce"
									src="../assets/store_pic_dynamic_1.png" style="width:100px;height: auto;" />
							</div>
						</div>
						<div class="flex-row-start-center" style="width: 100%;margin-top: 50px;">
							<img src="../assets/home_guanyu5.png" style="width: 100px;height: auto;" />
							<a href="/store" class="t5BoldFont whiteColor flex-row-start-center" style="width: 100px;">
								<img src="../assets/getmore.png"
									style="width: 100px;height: auto;margin-left: 20px;z-index: 3;" />
							</a>
						</div>
					</div>
					<div ref="card3" class="flex-coloumn-center-center bgimg"
						style="width: 100%;padding: 20px 20px;box-sizing: border-box;border: 1px solid #5f5f5f;margin-top: 10px;">
						<div class="testright flex-column-center-start" style="white-space: pre-wrap;width: 100%">
							<img src="../assets/home_miandan.png" style="width: 300px;height: auto;" />
							<div class="flex-column-center-start" style="padding: 20px 0;">
								<span class="whiteColor t5BoldFont"
									style="line-height: 1.8;text-indent: 2em;">{{card3Note1}}</span>
								<span class="whiteColor t5BoldFont"
									style="line-height: 1.8;text-indent: 2em;">{{card3Note2}}</span>
								<span class="whiteColor t5BoldFont"
									style="line-height: 1.8;text-indent: 2em;">{{card3Note3}}</span>
							</div>
							<a href="/dishes?position=card2" class="t5BoldFont whiteColor flex-row-start-center"
								style="width: 100px;">
								<img src="../assets/getmore.png" style="width: 100px;height: auto;" />
							</a>
						</div>
						<div class="flex-column-center-center" style="width: 100%;position: relative;margin-top: 20px;">
							<img src="../assets/gf2.png" style="width: 100%;height: auto;" />
							<div ref="animateCard3">
								<img v-if="shouldCard3Animate" class="movedownToup" src="../assets/ipone.png"
									style="position: absolute;bottom: 0px;right: 0px;width:100px;height: auto;" />
							</div>
						</div>
					</div>
					<!-- 水平匀速循环滚动的图片 -->
					<div ref="card4" class="flex-column-start-start bgimg"
						style="width: 100%;padding: 20px 20px;box-sizing: border-box;border: 1px solid #5f5f5f;margin-top: 10px;height: 350px;">
						<div class="flex-column-start-start" style="width: 100%;height: 100%;">
							<img src="../assets/home_meishi.png" style="width: 150px;height: auto;" />
							<div class="flex-row-center-center" style="flex: 1;width: 100%;">
								<!-- <dishes-banner></dishes-banner> -->
								<seamless-scroll style="width: 100%;height: 100%;"></seamless-scroll>
							</div>
						</div>
					</div>
					<bottom-view style="width: 100%;"></bottom-view>
				</div>
			</div>
			<div v-if="shouldCard4Animate" class="bgcolor"
				style="width: 100%;position: fixed;right: 0;bottom: 0;height: 100vh;">
			</div>
		</div>

	</div>
</template>

<script>
	import {
		getStoreList
	} from '../api/manageMent.js'
	import dishesBanner from '../components/dishesBanner.vue'
	import bottomView from '../components/bottomView.vue'
	import seamlessScroll from '../components/seamlessScroll.vue'
	export default {
		components: {
			bottomView,
			dishesBanner,
			seamlessScroll
		},
		data() {
			return {
				card1Note: '老船长创立于2015年，一家以肉蟹煲为大单品的连锁餐饮品牌，坚持新鲜，活蟹现做为经营理念，目标消费群体是18-35岁的年轻人。在肉蟹煲细分领域里，开创出全明档出餐模式，让消费者真切感受到“在老船长吃的每一只蟹都是鲜活的”！',
				card3Note1: '在老船长，我们始终秉持着对食材新鲜度的极致追求。我们深知，食材的新鲜程度直接影响到每一位顾客的用餐体验，因此，我们郑重承诺:“蟹不鲜，就免单”。 ',
				card3Note2: '这句承诺并非空谈，而是我们对自身产品质量的严格把控和自信体现。我们的蟹，每日都直接从遥远的孟加拉湾空运而来，确保每一只蟹都鲜活、饱满、肉质鲜嫩。在到达餐厅后，我们的厨师会进行严格的筛选和处理，只选用最新鲜、最优质的蟹进行烹饪。我们所说的“蟹不鲜，就免单”，意味着如果顾客在用餐过程中发现我们的蟹不新鲜，或者对蟹的新鲜度有任何疑问，我们都将无条件地为顾客免单。这不仅是对我们产品质量的自信，更是对每一位顾客负责的态度。',
				card3Note3: '在我们老船长，您不仅可以品尝到最新鲜、最美味的肉蟹煲，更能感受到我们对食材和服务的执着追求。我们期待每一位顾客的光临，相信您在这里一定能享受到一次满意的用餐体验。',
				shouldCard1Animate: false,
				shouldCard2Animate: false,
				shouldCard3Animate: false,
				shouldCard4Animate: false,

				pageWidth: window.innerWidth,
				pageHeight: window.innerHeight,

				imgStyle: {
				        transform: 'rotate(0deg)', // 初始旋转设置为0度
				      },
				      startX: 0,
				      startY: 0,
			};
		},
		mounted() {
			window.addEventListener('resize', this.handleResize);
			const observer1 = new IntersectionObserver(entries => {
				if (entries[0].isIntersecting) {
					// 目标div进入视口时触发提示
					// this.shouldCard1Animate = true
					// console.log('进入card1');
					// 如果只想触发一次提示，可以在这里取消观察
					// observer.unobserve(this.$refs.targetDiv);
				} else {
					console.log('离开card1');
					this.shouldCard1Animate = false
				}
			});
			observer1.observe(this.$refs.card1);
			
			const observer11 = new IntersectionObserver(entries => {
				if (entries[0].isIntersecting) {
					// 目标div进入视口时触发提示
					this.shouldCard1Animate = true
					console.log('进入card1');
					// 如果只想触发一次提示，可以在这里取消观察
					// observer.unobserve(this.$refs.targetDiv);
				} else {
					// console.log('离开card1');
					// this.shouldCard1Animate = false
				}
			});
			observer11.observe(this.$refs.animateCard1);
			
			const observer2 = new IntersectionObserver(entries => {
				if (entries[0].isIntersecting) {
					// 目标div进入视口时触发提示
					// this.shouldCard2Animate = true
					// console.log('进入card2');
					// 如果只想触发一次提示，可以在这里取消观察
					// observer.unobserve(this.$refs.targetDiv);
				} else {
					console.log('离开card2');
					this.shouldCard2Animate = false
				}
			});
			observer2.observe(this.$refs.card2);
			
			const observer22 = new IntersectionObserver(entries => {
				if (entries[0].isIntersecting) {
					// 目标div进入视口时触发提示
					this.shouldCard2Animate = true
					console.log('进入card2');
					// 如果只想触发一次提示，可以在这里取消观察
					// observer.unobserve(this.$refs.targetDiv);
				} else {
					// console.log('离开card2');
					// this.shouldCard2Animate = false
				}
			});
			observer22.observe(this.$refs.animateCard2);

			const observer3 = new IntersectionObserver(entries => {
				if (entries[0].isIntersecting) {
					// 目标div进入视口时触发提示
					// this.shouldCard3Animate = true
					// console.log('进入card3');
					// 如果只想触发一次提示，可以在这里取消观察
					// observer.unobserve(this.$refs.targetDiv);
				} else {
					console.log('离开card3');
					this.shouldCard3Animate = false
				}
			});
			observer3.observe(this.$refs.card3);
			
			const observer33 = new IntersectionObserver(entries => {
				if (entries[0].isIntersecting) {
					// 目标div进入视口时触发提示
					this.shouldCard3Animate = true
					console.log('进入card3');
					// 如果只想触发一次提示，可以在这里取消观察
					// observer.unobserve(this.$refs.targetDiv);
				} else {
					// console.log('离开card3');
					// this.shouldCard3Animate = false
				}
			});
			observer33.observe(this.$refs.animateCard3);

			const observer4 = new IntersectionObserver(entries => {
				if (entries[0].isIntersecting) {
					// 目标div进入视口时触发提示
					this.shouldCard4Animate = true
					console.log('进入card4');
					// 如果只想触发一次提示，可以在这里取消观察
					// observer.unobserve(this.$refs.targetDiv);
				} else {
					console.log('离开card4');
					this.shouldCard4Animate = false
				}
			});
			observer4.observe(this.$refs.card4);
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.handleResize);
		},
		methods: {
			handleResize() {
				this.pageWidth = window.innerWidth;
			},
			handleMouseMove(event) {
			      const rect = event.target.getBoundingClientRect();
			      const centerX = rect.left + rect.width / 2;
			      const centerY = rect.top + rect.height / 2;
			 
			      // 计算鼠标相对于容器中心的角度
			      const dx = event.clientX - centerX;
			      const dy = event.clientY - centerY;
			      const angle = Math.atan2(dy, dx) * 180 / Math.PI;
			 
			      // 设置图片的旋转样式
			      this.imgStyle.transform = `rotate(${angle}deg)`;
			    },
			    resetRotation() {
			      this.imgStyle.transform = 'rotate(0deg)'; // 鼠标离开时重置旋转
			    },
		}

	};
</script>

<style scoped>
	.image-container {
		overflow: hidden;
		position: relative;
		width: 300px;
		/* Adjust width and height as needed */
		height: 300px;
	}
	.guoClass{
		width: 275px;
		height: 275px;
	}

	.image-container img {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
		/* Adjust width as needed */
		height: auto;
		/* Maintain aspect ratio */
		cursor: pointer;
		transition: transform 0.1s ease-in-out;
		/* Smooth rotation */
	}

	.bgcolor {
		background-color: #272727;
	}

	.bgimg {
		background-image: url('~@/assets/bg.png');
		background-size: cover;
		/* 背景图片铺满元素 */
		background-position: center;
		/* 背景图片居中显示 */
		background-repeat: no-repeat;
		/* 背景图片不重复 */
	}

	.bounceleft {
		animation-name: moveleft;
		animation-duration: 0.6s;
		animation-timing-function: ease-in-out;
		animation-iteration-count: 1;
		animation-delay: 0s;
	}

	@keyframes moveleft {
		from {
			transform: translateX(-100%);
		}

		to {
			transform: translateX(0);
		}
	}
	
	.movedownToup {
		animation-name: moveup;
		animation-duration: 0.6s;
		animation-timing-function: ease-in-out;
		animation-iteration-count: 1;
		animation-delay: 0s;
	}

	.bounce {
		animation-name: moveup, bounce;
		animation-duration: 0.6s, 3s;
		animation-timing-function: ease-in-out, ease-in-out;
		animation-iteration-count: 1, infinite;
		animation-delay: 0s, 1s;
	}

	@keyframes moveup {
		from {
			transform: translateY(300px);
			opacity: 0;
		}

		to {
			transform: translateY(0px);
			opacity: 1;
		}
	}

	@keyframes bounce {

		0%,
		100% {
			transform: translateY(0px);
		}

		50% {
			transform: translateY(100px);
		}
	}

	.slide-out-down {
		animation: slideOutDown 2s ease forwards;
	}

	@keyframes slideOutDown {
		from {
			transform: translateY(-100%);
			opacity: 0;
		}

		to {
			transform: translateY(0px);
			opacity: 1;
		}
	}

	.testright {
		animation: slideOutRight 2s ease forwards;
	}

	@keyframes slideOutRight {
		from {
			transform: translateX(100%);
			opacity: 0;
		}

		to {
			transform: translateX(0px);
			opacity: 1;
		}
	}



	::-webkit-scrollbar {
		width: 0 !important;
	}

	::-webkit-scrollbar {
		width: 0 !important;
		height: 0;
	}
</style>