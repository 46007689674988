const serverapiUrl='https://www.lczrxb.com/prod-api'
// const serverapiUrl='http://114.55.2.142:8089/'


function getServerapiUrl () {
  return serverapiUrl
}
module.exports = {
	getServerapiUrl
}
