<template>
	<div class="">
		<div v-if="pageWidth >= 1005">
			<div class="flex-row-spacebetween-center"
				style="width: 100%;height: 150px;background-color: #b68f51;padding: 20px 30px;box-sizing: border-box;z-index: 1000;">
				<div class="flex-column-center-center" style="width: 400px;">
					<img src="../assets/storeTitle.png" style="width: 100%;height: 100%;" />
				</div>
				<div class="flex-column-center-center" style="flex: 1;margin-right: 40px;">
					<div class="flex-row-end-start" style="width: 100%;">
						<div class="flex-column-center-center" style="width: 80px;">
							<a href="https://weibo.com/u/7884991490" target="_blank">
								<img src="../assets/temp_icon1.png" style="width: 48px;height: auto;" />
							</a>
						</div>
						<div class="flex-column-center-center" style="width: 80px;">
							<a href="https://www.xiaohongshu.com/user/profile/5e1dde6f00000000010028cd?xhsshare=CopyLink&appuid=58cfa88d6a6a696f181e5877&apptime=1720409432" target="_blank">
								<img src="../assets/temp_icon2.png" style="width: 48px;height: auto;" />
							</a>
						</div>
						<div class="flex-column-center-center" style="width: 80px;">
							<a href="https://v.douyin.com/i6pSAq5k/ 8@0.com :3pm" target="_blank">
								<img src="../assets/temp_icon3.png" style="width: 48px;height: auto;" />
							</a>
						</div>
						<div class="flex-column-center-center" style="width: 80px;">
							<a href="weixin://profile/gh_9bc991a68666" target="_blank">
								<img src="../assets/temp_icon4.png" style="width: 48px;height: auto;" />
							</a>
						</div>
					</div>
					<div class="flex-row-end-center whiteColor t1BoldFont" style="width: 100%;margin-top: 10px;">
						Copyright@2024 老船长肉蟹煲 THE CAPTAIN.粵ICP备13025498号 Powered by vancheer
					</div>
				</div>
				<div class="flex-column-center-center">
					<img src="../assets/temp_right.png" style="width: 100px;height: 100px;" />
				</div>
			</div>
		</div>
		<div v-else>
			<div class="flex-column-center-center"
				style="width: 100%;background-color: #b68f51;z-index: 99;padding: 30px 30px;box-sizing: border-box;">
				<div class="flex-column-center-center">
					<img src="../assets/storeTitle.png" style="width: 100%;height: auto;" />
				</div>
				<div class="flex-row-center-center" style="width: 100%;margin-top: 20px;">
					<div class="flex-column-center-center" style="width: 80px;">
						<a href="https://weibo.com/u/7884991490" target="_blank">
							<img src="../assets/temp_icon1.png" style="width: 48px;height: auto;" />
						</a>
					</div>
					<div class="flex-column-center-center" style="width: 80px;">
						<a href="https://www.xiaohongshu.com/user/profile/5e1dde6f00000000010028cd?xhsshare=CopyLink&appuid=58cfa88d6a6a696f181e5877&apptime=1720409432" target="_blank">
							<img src="../assets/temp_icon2.png" style="width: 48px;height: auto;" />
						</a>
					</div>
					<div class="flex-column-center-center" style="width: 80px;">
						<a href="https://v.douyin.com/i6pSAq5k/ 8@0.com :3pm" target="_blank">
							<img src="../assets/temp_icon3.png" style="width: 48px;height: auto;" />
						</a>
					</div>
					<div class="flex-column-center-center" style="width: 80px;">
						<a href="https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=Mzg3NTg3NTA2Mg==&scene=110#wechat_redirect" target="_blank">
							<img src="../assets/temp_icon4.png" style="width: 48px;height: auto;" />
						</a>
					</div>
				</div>
				<div class="flex-column-center-center">
					<img src="../assets/temp_right.png" style="width: 100px;height: auto;margin: 20px 0;" />
				</div>
				<div class="flex-row-center-center whiteColor t1BoldFont" style="width: 100%;text-align: center;">
					Copyright@2024 老船长肉蟹煲 THE CAPTAIN.粵ICP备13025498号 Powered by vancheer
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "bottomView",
		props: {
			title: {
				type: String,
				default: ''
			},
		},
		data() {
			return {
				pageWidth: window.innerWidth,
			}
		},
		created() {

		},
		mounted() {
			window.addEventListener('resize', this.handleResize);
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.handleResize);
		},
		methods: {
			handleResize() {
				this.pageWidth = window.innerWidth;
			},
		}
	}
</script>

<style lang="scss" scoped>

</style>