<template>
	<div>
		<div class="flex-column-center-center" style="width: 100%;height: 100%;background-color: transparent">
			<div id="box" class="flex-row-start-center" @touchend="bindtouchend(scrollTime)">
				<ul id="listbox1" class="flex-row-start-center">
					<ul>
						<li v-for="(item, index) in list" :key="index" class="flex-row-center-center" @mouseenter="mouseenterEvent(index)"
				@mouseleave="mouseleaveEvent(scrollTime)">
							<img v-if="markIndex==index" :src="item.picSelected" style="width: 90%;height: 100%;"/>
							<img v-else :src="item.pic" style="width: 90%;height: 100%;"/>
						</li>
					</ul>
				</ul>
				<ul id="listbox2" class="flex-row-start-center">
					<ul>
							<li v-for="(item, index) in list" :key="index" class="flex-row-center-center" @mouseenter="mouseenterEvent(index)"
					@mouseleave="mouseleaveEvent(scrollTime)">
								<img v-if="markIndex==index" :src="item.picSelected" style="width: 90%;height: 100%;"/>
								<img v-else :src="item.pic" style="width: 90%;height: 100%;"/>
							</li>
						</ul>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				list: [{
						title: '这是标题1',
						pic: '/imgs/bar_1.png',
						picSelected: '/imgs/bar_1_selected.png',
						show: false
					},
					{
						title: '这是标题2',
						pic: '/imgs/bar_2.png',
						picSelected: '/imgs/bar_2_selected.png',
						show: false
					},
					{
						title: '这是标题3',
						pic: '/imgs/bar_3.png',
						picSelected: '/imgs/bar_3_selected.png',
						show: false
					},
					{
						title: '这是标题4',
						pic: '/imgs/bar_4.png',
						picSelected: '/imgs/bar_4_selected.png',
						show: false
					},
					{
						title: '这是标题5',
						pic: '/imgs/bar_5.png',
						picSelected: '/imgs/bar_5_selected.png',
						show: false
					},
					{
						title: '这是标题6',
						pic: '/imgs/bar_6.png',
						picSelected: '/imgs/bar_6_selected.png',
						show: false
					},
					{
						title: '这是标题7',
						pic: '/imgs/bar_7.png',
						picSelected: '/imgs/bar_7_selected.png',
						show: false
					},
					{
						title: '这是标题8',
						pic: '/imgs/bar_8.png',
						picSelected: '/imgs/bar_8_selected.png',
						show: false
					},
					{
						title: '这是标题9',
						pic: '/imgs/bar_9.png',
						picSelected: '/imgs/bar_9_selected.png',
						show: false
					},
					{
						title: '这是标题10',
						pic: '/imgs/bar_10.png',
						picSelected: '/imgs/bar_10_selected.png',
						show: false
					},
					{
						title: '这是标题11',
						pic: '/imgs/bar_11.png',
						picSelected: '/imgs/bar_11_selected.png',
						show: false
					},
					{
						title: '这是标题12',
						pic: '/imgs/bar_12.png',
						picSelected: '/imgs/bar_12_selected.png',
						show: false
					},
					{
						title: '这是标题13',
						pic: '/imgs/bar_13.png',
						picSelected: '/imgs/bar_13_selected.png',
						show: false
					},
					{
						title: '这是标题14',
						pic: '/imgs/bar_14.png',
						picSelected: '/imgs/bar_14_selected.png',
						show: false
					},
					{
						title: '这是标题15',
						pic: '/imgs/bar_15.png',
						picSelected: '/imgs/bar_15_selected.png',
						show: false
					},
					{
						title: '这是标题16',
						pic: '/imgs/bar_16.png',
						picSelected: '/imgs/bar_16_selected.png',
						show: false
					},
					{
						title: '这是标题17',
						pic: '/imgs/bar_17.png',
						picSelected: '/imgs/bar_17_selected.png',
						show: false
					},
					{
						title: '这是标题18',
						pic: '/imgs/bar_18.png',
						picSelected: '/imgs/bar_18_selected.png',
						show: false
					},
					{
						title: '这是标题19',
						pic: '/imgs/bar_19.png',
						picSelected: '/imgs/bar_19_selected.png',
						show: false
					}
				],
				timer: null,
				scrollTime: 20,
				markIndex:null
			};
		},
		methods: {
			scroll(t) {
				var ul1 = document.getElementById("listbox1");
				// var ul2 = document.getElementById("listbox2");
				// var ulbox = document.getElementById("box");
				// ul2.innerHTML = ul1.innerHTML;
				ulbox.scrollLeft = 0; // 开始无滚动时设为0
				this.timer = setInterval(this.rollStart, t);
			},
			// 开启滚动
			rollStart() {
				var ul1 = document.getElementById("listbox1");
				var ulbox = document.getElementById("box");
				if (ulbox.scrollLeft >= ul1.scrollWidth) {
					ulbox.scrollLeft = 0;
				} else {
					ulbox.scrollLeft++;
				}
			},
			// 鼠标移入关闭定时器
			mouseenterEvent(index) {
				// this.$message('mouseenter ' + index);
				if (this.timer) {
					clearInterval(this.timer);
					this.timer = null;
				}
				this.markIndex=index
			},
			// 鼠标移出重新调用定时器
			mouseleaveEvent(t) {
				this.markIndex=null
				if (!this.timer) {
					this.timer = setInterval(this.rollStart, t);
				}
			},
			bindtouchend(t){
				this.markIndex=null
				if (!this.timer) {
					this.timer = setInterval(this.rollStart, t);
				}
			}
		},
		mounted() {
			this.scroll(this.scrollTime);
		},
		destroyed() {
			if (this.timer) {
				clearInterval(this.timer);
				this.timer = null;
			}
		},
	};
</script>

<style lang="scss" scoped>
	#box {
		width: 100%;
		height: 100%;
		//溢出隐藏
		overflow-x: scroll;
		// display: inline-block;
		white-space: nowrap;
	}

	#listbox1 {
		width: 475vw;
		height: 100%;
		box-sizing: border-box;
		// display: inline-block;
		ul {
			width: 100%;
			height: 100%;
			box-sizing: border-box;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			li {
				width: 25vw;
				height: auto;
			}
		}

	}

	#listbox2 {
		width: 475vw;
		height: 100%;
		box-sizing: border-box;
		// display: inline-block;
		ul {
			width: 100%;
			height: 100%;
			box-sizing: border-box;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			li {
				width: 25vw;
				height: auto;
			}
		}
	}
</style>




<!--
<template>
  <div>
 
    <div
      id="box"
      @mouseenter="mouseenterEvent()"
      @mouseleave="mouseleaveEvent(scrollTime)"
    >
      <ul id="listbox1">
        <li v-for="(item, index) in list" :key="index">{{ item }}</li>
      </ul>
      <ul id="listbox2">
  
      </ul>
    </div>
  </div>
</template>
 
<script>
export default {
  data() {
    return {
      list: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      timer: null,
      scrollTime: 20,
    };
  },
  methods: {
    scroll(t) {
      var ul1 = document.getElementById("listbox1");
 
      var ul2 = document.getElementById("listbox2");
 
      var ulbox = document.getElementById("box");
 
      ul2.innerHTML = ul1.innerHTML;
 
      ulbox.scrollTop = 0; // 开始无滚动时设为0
 
      this.timer = setInterval(this.rollStart, t);
    },
    // 开启滚动
    rollStart() {
      // 上面声明的DOM对象为局部对象需要再次声明
      // 无缝轮播需要两个相同内容的盒子
      var ul1 = document.getElementById("listbox1");
 
      // var ul2 = document.getElementById("listbox2");
 
      var ulbox = document.getElementById("box");
 
      // 正常滚动不断给scrollTop的值+1,当滚动高度大于列表内容高度时恢复为0
 
      if (ulbox.scrollTop >= ul1.scrollHeight) {
        ulbox.scrollTop = 0;
      } else {
        ulbox.scrollTop++;
      }
    },
    // 鼠标移入关闭定时器
    mouseenterEvent() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
      // // 只显示一个盒子的内容
      // document.getElementById("listbox2").style.display = "none";
    },
    // 鼠标移出重新调用定时器
    mouseleaveEvent(t) {
      console.log(1);
      if (!this.timer) {
        this.timer = setInterval(this.rollStart, t);
      }
      //  document.getElementById("listbox2").style.display = "block";
    },
  },
  mounted() {
    //开启滚动
    this.scroll(this.scrollTime);
  },
  destroyed() {
    // 页面销毁清除定时器
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },
};
</script>
 
<style lang="less" scoped>
#box {
  width: 500px;
  height: 200px;
  border: 1px solid #000;
  //溢出隐藏
  overflow: hidden;
  &:hover {
    // 实现Y轴可滚动
    overflow-y: scroll;
  }
  &::-webkit-scrollbar {
    width: 0px;
  }
}
#listbox1 {
  width: 500px;
  height: 400px;
  border: 1px solid red;
  box-sizing: border-box;
 
  li {
    height: 40px;
    border: 1px solid blue;
    box-sizing: border-box;
  }
}
#listbox2 {
  width: 500px;
  height: 400px;
  border: 1px solid red;
  box-sizing: border-box;
 
  li {
    height: 40px;
    border: 1px solid blue;
    box-sizing: border-box;
  }
}
</style>
-->