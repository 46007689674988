import axios from 'axios'
import global from '../plugins/global.js'

const apiUrl = global.getServerapiUrl()
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
	baseURL: apiUrl, // api的base_url
	timeout: 15000, // 请求超时时间
})
// request拦截器
service.interceptors.request.use(config => {
	return config
}, error => {
	Promise.reject(error)
})
// respone拦截器
service.interceptors.response.use(
	response => {
		const res = response.data
		return res
	},
	error => {
		console.log('err' + error) // for debug
		return Promise.reject(error)
	}
)

export default service